<template>
  <div v-if="order" class="row">
    <div class="col-12" v-if="editFiles">
        <upload-file-drag-drop-multiple
            v-model="orderInvoices"
            name="invoices"
            :disabled="uploading"
            :multiple="true"
            :label="''"
            :placeholder="'orders.edit.upload_invoice'"
            :files-list-label="'orders.edit.uploaded_invoices'"
            :empty-message="'orders.edit.uploaded_empty'">
        </upload-file-drag-drop-multiple>
        <div class="row mt-2">
          <div class="col-6 text-left">
            <Button @click="cancel" v-if="!uploading" :name="$t('orders.edit.cancel_invoice')" type="cancel"/>
          </div>
          <div class="col-6 text-right">
            <Button @click="save()" v-if="!uploading" :name="$t('orders.edit.save_invoice')" type="confirm"/>
            <i class="icon-spinner2 spinner icon-3x" v-else></i>
          </div>
        </div>
    </div>

    <div  v-else class="col-12 text-center">
      <div class="p-2 mb-3" v-if="order.getInvoice() && order.getInvoice().length">
        <h4 class="mb-2">{{$t('orders.edit.invoices_available')}}</h4>

        <div v-for="(file, index) in order.getInvoice()" :key="index">
          <a class="" :href="file.url" target="_blank">{{ $t('orders.edit.download_invoice') }} #{{index+1}}</a>
        </div>
      </div>
      <div class="py-4 text-muted font-size-lg" v-if="!order.getInvoice().length">{{$t('orders.edit.no_invoices')}}</div>
      <Button @click="editFiles = true" :name="$t('orders.edit.modify_invoice')" type="modify"/>
    </div>

  </div>
</template>

<script>
import {LoadingModalMixin, ConfirmationModalMixin} from "@/mixins/ModalMixin";
import {ScreenSizeMixin, AuthorizedActionsMixin, DatesMixin, NumbersMixin} from "@/mixins/GeneralMixin";
import UploadFileDragDropMultiple from "@/components/elements/UploadFileDragDropMultiple";
import Order from "@/entities/Order";
import Button from "@/components/elements/Button";

export default {
  name: "OrderDocuments",
  components: {
    Button,
    UploadFileDragDropMultiple
    },
  mixins: [LoadingModalMixin, ConfirmationModalMixin, ScreenSizeMixin, AuthorizedActionsMixin, DatesMixin, NumbersMixin],
  emits: ['update'],
  props: {
    order: {
      type: [Order],
      required: true
    }
  },
  watch: {
    order: {
      handler() {
        this.orderInvoices = [...this.order.getInvoice()];
      },
      deep: true
    }
  },
  data: function () {
    return {
      orderInvoices: [],
      editFiles: false,
      uploading: false
    }
  },
  mounted() {
    this.orderInvoices = [...this.order.getInvoice()];
    //if (!this.orderInvoices.length)
      //this.editFiles = true;
  },
  unmounted() {
  },
  computed: {

  },
  methods: {
    cancel() {
      this.editFiles = false;
      this.orderInvoices = [...this.order.getInvoice()];
    },
    save() {
      //let that = this;
      //that.queueJob();

      this.uploading = true;

      let formData = new FormData();
      //console.log(this.orderInvoices);
      this.orderInvoices.forEach((invoice) => {
        if (invoice instanceof File) {
          formData.append('new_invoice[]', invoice);
        }
        else {
          formData.append('old_invoice[]', JSON.stringify(invoice));
        }
      });
      this.axios.post(this.$store.state.config.getUploadOrderInvoiceUri(this.order.getId()), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
          (response) => {
            let content = response.data;
            this.$emit('update', content.data);
            this.editFiles = false;
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally( () => {
        this.uploading = false;
        //that.completeJob(true);
      });
    }
  }
}
</script>

<style scoped>

</style>
