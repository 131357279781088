<template>
  <Modal :name="title && $t(title)" :dismissible="true" :with-footer="false">
    <template v-slot:default>
      <OrderDocuments v-if="order" :order="order" v-on:update="invoiceUpdated($event)"></OrderDocuments>
    </template>
  </Modal>
</template>

<script>
import OrderDocuments from "@/components/order/backoffice/OrderDocuments";
import Modal from "@/components/elements/Modal";

export default {
  name: "OrderDocumentsModal",
  components: {
    Modal,
    OrderDocuments
  },
  props: {
    order: Object,
    title: {
      type: String,
      default: null,
    }
  },
  methods: {
    invoiceUpdated(order) {
      this.$emit('update',order);
    }
  }
}
</script>

<style scoped>
</style>
