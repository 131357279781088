<template>
  <Table>
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="elements.getSize()">
      <TableElement v-bind:class="{'bg-warning': element.isQtyChanged(), 'bg-danger': element.hasNoQty()}" @click="$emit('details', element)" v-for="element in elements.getItems()" v-bind:key="element.getId()">
        <TableElementItem>
          <template v-slot:default>
            {{ element.getProduct().getDisplayName() }}
          </template>
        </TableElementItem>
        <TableElementItem>
          <template v-slot:default>
            <div class="d-lg-flex">
              <TextInput class="d-lg-inline w-lg-75" v-bind:class="isLimitedCheck(element) && 'error'" type="number" min="0" :max="isLimited ? element.getInitialRealQty() : Number.MAX_VALUE" @click.stop :reference="element" @input="changeQtyFromRealQty" :value="''+element.getRealQty()" :disabled="!isEditable"/>
              <span class="ml-1 d-block d-lg-inline align-self-lg-center w-100 text-right w-lg-auto pr-1 pr-lg-0">{{element.getProduct().getUnit()}}</span>
            </div>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Qty :amount="element.getInitialRealQty()" :unit="element.getProduct().getUnit()"/>
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <FarmerName :color="element.isQtyChanged() ? '':'text-warning'" :farmer="element.getProduct().getFarm()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Price :amount="element.getProduct().getBuyingPriceWithVat()" :currency="element.getCurrency()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Qty :amount="element.getQty()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            {{ formatMomentDate(order.getCycle().getDeliverOrder()) }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Price :amount="element.getTotalBuyingPriceWithVat()" :currency="element.getCurrency()" />
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="$t('orders.edit.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import TextInput from "@/components/elements/TextInput";
import Price from "@/components/elements/Price";
import {DatesMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import Qty from "@/components/elements/Qty";
import FarmerName from "@/components/farmer/backoffice/FarmerName";

export default {
  name: "OrderItemTable",
  components: {FarmerName, Qty, Price, TextInput, TableElement, TableElementItem, Table},
  mixins: [ScreenSizeMixin, DatesMixin],
  emits: ['details'],
  props: {
    order: Object,
    elements: Object,
    isEditable: {
      type: Boolean,
      default: false,
    },
    isLimited: {
      type: Boolean,
      default: false,
    }
  },
  data: function() {
    return {
      allHeaders: [
        'orders.edit.product',
        'orders.edit.real_quantity',
        'orders.edit.initial_quantity',
        'orders.edit.farm',
        'orders.edit.price',
        'orders.edit.quantity',
        'orders.edit.collection_day',
        'orders.edit.total_price_with_vat',
      ],
      responsiveHeaders: [
        'orders.edit.product',
        'orders.edit.real_quantity',
        'orders.edit.total_price_with_vat',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
  },
  methods: {
    isLimitedCheck: function (element) {
      return this.isLimited && element.getRealQty() > element.getInitialRealQty()
    },
    changeQtyFromRealQty(reference, realQty) {
      try {
        if (reference.hasPacking()) {
          reference.setQty(Math.round((parseFloat(realQty) / reference.getPacking().getValue()) * 100) / 100);
        } else {
          reference.setQty(Math.round(parseFloat(realQty) * 100) / 100);
        }
        reference.setRealQty(realQty);
      } catch (e) {
        console.warn(e);
      }
    },
  }
}
</script>

<style scoped>

</style>