<template>
  <Modal :dismissible="true">
    <template v-slot:default>
      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('orders.edit.details.name')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{item.getProduct().getDisplayName()}}
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('orders.edit.details.farmer')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11 font-weight-semibold">
          {{ item.getProduct().getFarm().getName() }}
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('orders.edit.details.price')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Price :amount="item.getProduct().getBuyingPriceWithVat()" :currency="item.getCurrency()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('orders.edit.details.qty')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Qty elementStyle="pl-0 pr-0 text-center col-12" :amount="item.getRealQty()" /> / #<Qty class="pl-0" elementStyle="text-center col-12" :amount="item.getQty()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('orders.edit.details.initial_qty')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Qty elementStyle="pl-0 pr-0 text-center col-12" :amount="item.getInitialRealQty()" /> / #<Qty class="pl-0" elementStyle="text-center col-12" :amount="item.getInitialQty()" />
        </dd>
        <dt class="font-italic col-4 col-md-2 col-xl-1 font-weight-light">{{$t('orders.edit.details.total')}}</dt>
        <dd class="font-italic col-8 col-md-10 col-xl-11 mb-1 font-weight-light">
          <Price :amount="item.getTotalBuyingPriceWithVat()" :currency="item.getCurrency()" />
        </dd>
      </dl>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Price from "@/components/elements/Price";
import Qty from "@/components/elements/Qty";

export default {
  name: "OrderItemDetailsModal",
  components: {Qty, Modal, Price},
  props: {
    item: Object,
  },
}
</script>

<style scoped>
</style>