<template>
  <div>
    <i :class="{'icon-2x': big, 'cursor-pointer': clickable}" v-if="notifiable" class="icon-bell2"  />
    <i :class="{'icon-2x': big, 'cursor-pointer': clickable}" v-else class="icon-bell-cross" />
  </div>
</template>

<script>
export default {
  name: "Notifiable",
  props: {
    notifiable: {
      type: Boolean,
      default: true
    },
    clickable: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
