<template>
  <Alert :type="addition && object.isEditable() ? 'info':'warning'" :styled="false" class="text-center w-xl-auto">
    <template v-slot:default>
      <span class="font-size-lg badge text-wrap">
        {{ object.isEditable() && addition ? $t('general.editable') : $t('general.immutable') }}
      </span>
    </template>
  </Alert>
</template>

<script>
import Alert from "@/components/elements/Alert";

export default {
  name: "ImmutabilityStatus",
  components: {Alert},
  props: {
    object: Object,
    addition: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>