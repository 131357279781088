<template>
  <div v-if="order" class="row">
    <ConfirmationModal @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
    <OrderItemDetailsModal v-if="selectedItem" @closemodal="selectedItem=null" :item="selectedItem" />
    <ShipmentDetailsModal v-if="showShipment" @closemodal="showShipment=false" :shipment="order.getShipment()" title="deliverynotes.shipments.modal.title_delivery" />

    <OrderDocumentsModal v-if="showDocuments" @closemodal="showDocuments=false"
                         :order="order" title="orders.edit.invoice"
                         @update="invoiceUpdated($event)"
    />

    <div class="col-md-12">
      <Card :title="$t('orders.edit.title', {customer: order.getContact()})">
        <template v-slot:header>
          <div class="row align-items-center justify-content-around">
            <Alert type="light" :styled="false" class="text-center w-100 w-sm-auto">
              <template v-slot:default>
                <Status :status="order.getStatus()" />
              </template>
            </Alert>
            <ImmutabilityStatus class="w-sm-50" :object="order" />
          </div>
        </template>
        <template v-slot:default>
          <div class="row text-center">
              <div class="col-md-3" :class="{'col-md-4':!order.getAddress().isSet()}">
                <Call :telephone="order.getTelephone()" :big="true" class="mr-2 d-inline-block" />
                <Notifiable class="d-inline-block" :big="true" @click="toggleNotify" :notifiable="order.canNotify()" :clickable="true" />
                <span class="d-block p-2">
                  {{ order.getContact() }}
                  <span v-show="order.getEmail()" class="d-block">{{ order.getEmail() }}</span>
                </span>
              </div>
            <div v-if="order.getAddress().isSet()" class="col-md-3">
              <Address class="align-self-end" :responsive="true" :big="true" :address="order.getAddress()" />
            </div>
            <div class="col-md-3" :class="{'col-md-4':!order.getAddress().isSet()}">
                <div class="p-2">
                  <span class="d-block">{{ $t('orders.edit.total_price_with_vat') }} <Price :amount="order.getTotalPriceWithVat()" :currency="order.getCurrency()" /></span>
                  <span>{{ $t('orders.edit.total_vat') }} <Price :amount="order.getTotalVat()" :currency="order.getCurrency()" /></span>
                </div>
              </div>
            <div class="col-md-3" :class="{'col-md-4':!order.getAddress().isSet()}">
                <div class="p-2">
                  <DayTimeEstimation :moment="order.getCycle().getDeliverOrder()" text="orders.edit.delivery" :hours="order.getDeliveryHours()" />
                  <span class="d-block">{{$t('orders.edit.weight', {bulk: formatQty(order.getTotalBulk()), pack: formatQty(order.getTotalPacked())})}}</span>
                </div>
              </div>
            </div>
            <br class="clearfix" />
            <div v-show="order.hasComment()" class="row">
              <div class="col-md-4">
                <p class="text-muted"> {{ order.getComment() }}</p>
              </div>
              <br class="clearfix"/>
            </div>
          <br class="clearfix" />
          <hr class="content-divider" />
            <div class="d-inline-block">
            <Button v-if="order.hasShipment()" @click="showShipment=true" type="eye" name="orders.edit.buttons.shipment" class="mb-3 mr-2"/></div>
          <div class="d-inline-block">
            <Button @click="showDocuments=true" type="eye" name="orders.edit.buttons.documents" :textRight="' ('+order.getInvoice().length+')'" class="mb-3 mr-2"/>
          </div>
          <br class="clearfix" />
          <OrderItemTable :order="order" @details="showItemDetails" :is-limited="order.isLimited()" :is-editable="order.isEditable()" :elements="order.getItemCollection()" />
        </template>
        <template v-slot:footer>
            <Button v-if="canApproveOrders && !isUpdate" v-bind:disabled="!order.isEditable()" @click="askConfirmation('orders.edit.confirm.confirm')" type="confirm" name="orders.edit.buttons.confirm" class="mb-2 mb-lg-0"/>
            <Button v-else-if="isUpdate" v-bind:disabled="!order.isEditable() || !order.hasChangedItems() || order.isLimitedAndHasInvalidQty()" @click="askConfirmation('orders.edit.confirm.update')" type="confirm" name="orders.edit.buttons.update" class="mb-0"/>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import OrderItemTable from "@/components/order/backoffice/OrderItemTable";
import Alert from "@/components/elements/Alert";
import Order from "@/entities/Order";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import Status from "@/components/elements/Status";
import Price from "@/components/elements/Price";
import ImmutabilityStatus from "@/components/elements/ImmutabilityStatus";
import Call from "@/components/elements/Call";
import Notifiable from "@/components/elements/Notifiable";
import {LoadingModalMixin, ConfirmationModalMixin} from "@/mixins/ModalMixin";
import OrderItemDetailsModal from "@/components/order/backoffice/OrderItemDetailsModal";
import {ScreenSizeMixin, AuthorizedActionsMixin, DatesMixin, NumbersMixin} from "@/mixins/GeneralMixin";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";
import ShipmentDetailsModal from "@/components/deliverynote/ShipmentDetailsModal";
import Address from "@/components/elements/Address";
import OrderDocumentsModal from "@/components/order/backoffice/OrderDocumentsModal";

export default {
  name: "OrderEdit",
  components: {
    OrderDocumentsModal,
    Address,
    ShipmentDetailsModal,
    DayTimeEstimation,
    OrderItemDetailsModal,
    Notifiable,
    Call, ImmutabilityStatus, Price, Status, ConfirmationModal, Alert, OrderItemTable, Card, Button},
  mixins: [LoadingModalMixin, ConfirmationModalMixin, ScreenSizeMixin, AuthorizedActionsMixin, DatesMixin, NumbersMixin],
  emits: ['update'],
  data: function () {
    return {
      order: null,
      showShipment: false,
      showDocuments: false,
      selectedItem: null,
    }
  },
  mounted() {
    this.getOrder();
  },
  unmounted() {
    this.$store.commit("breadcrumb", "");
  },
  computed: {
    isUpdate: function () {
      return (this.order.isEditable() && this.order.hasChangedItems()) || !this.order.isNew()
    }
  },
  methods: {
    onLoadingComplete() {
      if (this.initialise) {
        this.$emit('update');
        this.getOrder();
      }
    },
    getOrder() {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getOrderUri(this.$route.params.orderId)).then(
          response => {
            let content = response.data;
            that.order = new Order(content.data);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.$store.commit('breadcrumb', that.order && that.order.getContact());
        that.completeJob();
      });
    },
    confirmed()
    {
      this.closeConfirmation();
      if (this.isUpdate) {
        this.updateOrder();
      } else {
        this.confirmOrder()
      }
    },
    confirmOrder() {
      let that = this;

      this.queueJob()
      this.axios.put(this.$store.state.config.getApproveOrdersUri(this.$route.params.orderId)).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    updateOrder() {
      let that = this;
      this.order.getChangedItems().forEach(function (item) {
        that.queueJob();
        that.axios.put(that.$store.state.config.getOrdersItemUpdateUri(that.order.getId(), item.getId()), {qty: item.getQty()}).catch(
            error => {
              console.warn(error);
            }
        ).finally(function () {
          that.completeJob(true);
        });
      });
    },
    showItemDetails(item) {
      if (this.isScreenLgWidth) {
        return;
      }
      this.selectedItem = item;
    },
    toggleNotify() {
      let that = this;
      this.order.setCanNotify(!this.order.canNotify());
      this.queueJob();
      this.axios.put(this.$store.state.config.getOrdersContactUpdateUri(this.order.getId()), {can_notify: this.order.canNotify()}).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    invoiceUpdated(order) {
      this.order = new Order(order);
    }
  }
}
</script>

<style scoped>
.font-size-lg-custom {
  font-size: 2em;
}
</style>
