<template>
  <Modal :name="title && $t(title)" :dismissible="true" :with-footer="false">
    <template v-slot:default>
      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 font-weight-semibold">{{$t('deliverynotes.shipments.modal.awb')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-semibold">
          {{ shipment.getAwb() }}
        </dd>
        <dt class="col-4 col-md-2 mb-1 font-weight-semibold">{{$t('deliverynotes.shipments.modal.driver')}}</dt>
        <dd class="col-8 col-md-10 mb-1 font-weight-semibold">
          {{ shipment.getDriver().getName() }}
        </dd>

        <br class="clearfix"/>
        <br class="clearfix"/>

        <dt class="font-italic col-4 col-md-2 font-weight-light">{{$t('deliverynotes.shipments.modal.is_packing_ok')}}</dt>
        <dd class="font-italic col-8 col-md-10 mb-1 font-weight-light">
          {{ shipment.isPackingOk() ? $t('general.ok') : $t('general.issues') }}
        </dd>
        <dt class="font-italic col-4 col-md-2 font-weight-light">{{$t('deliverynotes.shipments.modal.comments')}}</dt>
        <dd class="font-italic col-8 col-md-10 mb-1 font-weight-light">
          {{ shipment.getQualityIssues() ? shipment.getQualityIssues() : '-' }}
        </dd>
      </dl>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
export default {
  name: "ShipmentDetailsModal",
  components: {Modal},
  props: {
    shipment: Object,
    title: {
      type: String,
      default: null,
    }
  }
}
</script>

<style scoped>

</style>